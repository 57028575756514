<template>
  <v-app>
    <v-main>
      <v-container class="fill-height justify-center">
        <NuxtPage />
      </v-container>
    </v-main>

    <Snackbar />
    <TheDialog />
  </v-app>
</template>
<script lang="ts">
import { mapMutations } from '~/assets/javascript/modules/vuex';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';

export default {
  name: 'Default',
  components: { Snackbar, TheDialog },
  setup() {
    useWorkspace();
    buildHead();

    return {
      ...mapMutations('workspace', ['resetWorkspace']),
    };
  },
  mounted() {
    const utmsObject = _pickBy(this.$route.query, (_value, key) => _startsWith(key, 'utm_'));

    if (!_isEmpty(utmsObject)) {
      window.analytics.track('referredFrom', utmsObject);
    }
  },
  unmounted() {
    this.resetWorkspace();
  },
};
</script>
